<template>
  <div>
    <a-form :form="form">
      <a-form-item
        label="结算规则信息"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-select @change="handleChange" v-model="settlement_info.settlement_id" disabled>
          <a-select-option :value="item.id" v-for="(item,index) in payRules" :key="index">
            <div>
              <a-row type="flex" justify="space-between" align="top">
                <a-col :flex="3">
                  <span>{{ item.title }}</span>
                </a-col>
                <a-col :flex="2" style="color: #8b8b8b;margin-left: 20px">
                  <span>{{ item.description }}</span>
                </a-col>
              </a-row>

            </div>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :wrapperCol="{span: 19, offset: 5}">
        <a-button style="margin-right: 8px" @click="prevStep">上一步</a-button>
        <a-button :loading="loading" type="primary" @click="nextStep">下一步</a-button>
      </a-form-item>
    </a-form>
    <a-divider />
    <div class="step-form-style-desc">
      <h3>说明</h3>
      <p>请填写商家的结算费率规则、特殊资质等信息</p>
    </div>
  </div>
</template>

<script>
const payRules = [
  { title: '在线图书/视频/音乐、游戏、网络直播、门户论坛、网络广告及推广、软件开发', id: 711, description: '费率1%，入账周期T+1，单笔收款限额' },
  { title: '财经资讯/荐股', id: 713, description: '费率0.6%，入账周期T+1，单笔收款限额' },
  { title: '众筹', id: 714, description: '费率0.6%，入账周期T+1' },
  { title: '保险公司、保险代理公司', id: 715, description: '费率0.6%，入账周期T+1' },
  { title: '餐饮、零售批发、网上综合商城、交通出行、生活娱乐服务、培训教育机构、民营医疗机构、缴费等', id: 716, description: '费率0.6%，入账周期T+1' },
  { title: '加油、物流快递、民办中小学及幼儿园', id: 717, description: '费率0.3%，入账周期T+1' },
  { title: '信用还款（不涉及理财）', id: 718, description: '费率0.2%，入账周期T+1，禁信用卡' },
  { title: '婚介平台、就业信息平台、话费代理充值等', id: 728, description: '费率0.6%，入账周期T+1，单笔收款限额' },
  { title: '水电煤暖气民生缴费', id: 730, description: '费率0.2%，入账周期T+1' },
  { title: '民办大学及学院', id: 769, description: '费率0%,入账周期T+1' }
]
let settlement_info = { settlement_id: 716, qualification_type: '零售批发/生活娱乐/网上商城/其他' }
export default {
  name: 'Step3',
  data () {
    return {
      payRules,
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 16 }, sm: { span: 16 } },
      form: this.$form.createForm(this),
      loading: false,
      timer: 0,
      settlement_info
    }
  },
  methods: {
    nextStep () {
      const that = this
      const { form: { validateFields } } = this
      that.loading = true
      validateFields((err, values) => {
        if (!err) {
          console.log('表单 values', values)
          that.timer = setTimeout(function () {
            that.loading = false
            that.$emit('nextStep', that.settlement_info, 3)
          }, 500)
        } else {
          that.loading = false
        }
      })
    },
    prevStep () {
      this.$emit('prevStep')
    },
    handleChange ({ title, description }) {
      console.log('111111111111', title, description)
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="less" scoped>
.stepFormText {
  margin-bottom: 24px;

  .ant-form-item-label,
  .ant-form-item-control {
    line-height: 22px;
  }
}

.step-form-style-desc {
  padding: 0 56px;
  color: rgba(0,0,0,.45);

  h3 {
    margin: 0 0 12px;
    color: rgba(0,0,0,.45);
    font-size: 16px;
    line-height: 32px;
  }

  h4 {
    margin: 0 0 4px;
    color: rgba(0,0,0,.45);
    font-size: 14px;
    line-height: 22px;
  }

  p {
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 22px;
  }
}

</style>
