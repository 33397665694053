var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"商户简称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'merchant_shortname',
          { rules: [ {required: true, message: '请输入商户简称' }]}]),expression:"[\n          'merchant_shortname',\n          { rules: [ {required: true, message: '请输入商户简称' }]}]"}],staticStyle:{"width":"60%","margin-right":"10px"},attrs:{"placeholder":"商户简称"}}),_c('a-tooltip',{style:({ marginLeft: `0px`, whiteSpace: 'nowrap' }),attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('div',[_c('p',[_vm._v("1、在支付完成页向买家展示，需与微信经营类目相关。")]),_c('p',[_vm._v("2、简称要求：")]),_c('p',[_vm._v("① 不支持单纯以人名来命名，若为个体户经营，可用“个体户+经营者名称”或“经营者名称+业务”命名，如“个体户张三”或“张三餐饮店”；")]),_c('p',[_vm._v("② 不支持无实际意义的文案，如“XX特约商户”、“800”、“XX客服电话XXX”；")])])]),_c('a-icon',{attrs:{"type":"question-circle"}})],2)],1),_c('a-form-item',{attrs:{"label":"客服电话","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'service_phone',
          { rules: [{required: true, message: '请输入客服电话'}] }]),expression:"[\n          'service_phone',\n          { rules: [{required: true, message: '请输入客服电话'}] }]"}],staticStyle:{"width":"60%","margin-right":"10px"},attrs:{"placeholder":"客服电话"}}),_c('a-tooltip',{style:({ marginLeft: `0px`, whiteSpace: 'nowrap' }),attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('div',[_c('p',[_vm._v("1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务")]),_c('p',[_vm._v("2、请确保电话畅通，以便入驻后平台回拨确认。")])])]),_c('a-icon',{attrs:{"type":"question-circle"}})],2)],1),_c('a-form-item',{attrs:{"label":"经营场景","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'sales_scenes_type',
          { rules: [{required: true, message: '请选择经营场景'}] }]),expression:"[\n          'sales_scenes_type',\n          { rules: [{required: true, message: '请选择经营场景'}] }]"}],attrs:{"options":_vm.plainOptions,"default-value":['小程序'],"disabled":""},on:{"change":_vm.onChange}}),_c('br'),_c('span',[_vm._v("请勾选实际售卖商品/提供服务场景（至少一项），以便为你开通需要的支付权限"),_c('a',{attrs:{"target":"_blank","href":"https://kf.qq.com/faq/170807nY7Jbi170807Yf6jIB.html"}},[_vm._v("填写经营场景指引")])]),_c('br'),_c('span',[_vm._v("建议只勾选目前必须的场景，以便尽快通过入驻审核，其他支付权限你可在入驻后再根据实际需要发起申请")])],1),_c('a-form-item',{attrs:{"label":"小程序APPID","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mini_program_sub_appid',
          { rules: [{required: true, message: '请输入小程序APPID'}] }]),expression:"[\n          'mini_program_sub_appid',\n          { rules: [{required: true, message: '请输入小程序APPID'}] }]"}],staticStyle:{"width":"60%","margin-right":"10px"},attrs:{"placeholder":"小程序APPID"}}),_c('br'),_c('span',[_c('span',[_vm._v("1、请填写已认证的小程序APPID。"),_c('a',{attrs:{"href":"https://kf.qq.com/faq/181105JJNbmm181105eUZfee.html","target":"_blank"}},[_vm._v("AppID查看指引")])]),_c('br'),_c('span',[_vm._v("2、完成进件后，系统发起特约商户号与该AppID的绑定（即配置为sub_appid，可在发起支付时传入）。")]),_c('br'),_c('span',[_vm._v("（1）若APPID主体与商家主体/服务商主体一致，则直接完成绑定。")]),_c('br'),_c('span',[_vm._v("（2）若APPID主体与商家主体/服务商主体不一致，则商户签约时显示《联合营运承诺函》，并且AppID的管理员需登录公众平台确认绑定意愿。")])])],1),_c('a-form-item',{attrs:{"label":"小程序截图","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('div',{staticClass:"clearfix"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mini_program_pics',
            { rules: [{required: true, message: '请上传小程序截图'}]
            },]),expression:"[\n            'mini_program_pics',\n            { rules: [{required: true, message: '请上传小程序截图'}]\n            },]"}],attrs:{"name":"file","accept":"image/png, image/jpeg, image/jpg","list-type":"picture-card","action":_vm.actionUrl,"data":_vm.new_multipart_params,"file-list":_vm.fileList,"before-upload":_vm.beforeUpload},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[(_vm.fileList.length < 5)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传 ")])],1):_vm._e()]),_c('span',[_vm._v("请提供展示商品/服务的页面截图/设计稿（最多5张），若小程序未建设完善或未上线请务必提供。")]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1)]),_c('a-form-item',{attrs:{"wrapperCol":{span: 19, offset: 5}}},[_c('a-button',{staticStyle:{"margin-right":"8px"},on:{"click":_vm.prevStep}},[_vm._v("上一步")]),_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.nextStep}},[_vm._v("下一步")])],1)],1),_c('a-divider'),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-form-style-desc"},[_c('h3',[_vm._v("说明")]),_c('p',[_vm._v("请填写商家的经营业务信息、售卖商品/提供服务场景信息")])])
}]

export { render, staticRenderFns }