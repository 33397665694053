<template>
  <div>
    <a-spin :spinning="appletLoading">
      <a-card :bordered="false" style="padding: 10px">
        <a-steps class="steps" :current="currentTab" v-if="!apply_success">
          <a-step v-for="(item,index) in stepTitles" :title="item.title" :key="index" />
        </a-steps>
        <div class="content" v-if="apply_success===0">
          <step1 v-show="currentTab === 0" @nextStep="nextStep"/>
          <step2 v-show="currentTab === 1" @nextStep="nextStep" @prevStep="prevStep"/>
          <step3 v-show="currentTab === 2" @nextStep="nextStep" @prevStep="prevStep"/>
          <step4 v-show="currentTab === 3" @nextStep="nextStep" @prevStep="prevStep"/>
          <step5 v-show="currentTab === 4" @prevStep="prevStep" @finish="finish"/>
        </div>
        <div v-if="apply_success===1 || apply_success===2 || apply_success===3">
          <template>
            <a-result
              status="success"
              :title="apply_title"
              :sub-title="apply_subtitle"
            >
              <template #extra>
                <img
                  v-if="qrData"
                  :src="qrData"
                  alt="扫描二维码"
                  width="200px"
                  height="200px">
                <br/>
                <a-button v-show="false" key="wechat_pay" type="primary" @click="wechatPay" v-if="apply_success===1">
                  更新
                </a-button>
              </template>
            </a-result>
          </template>
        </div>
        <div v-if="applyData">
          <a-descriptions style="width: 300px" title="主体类型" bordered>
            <a-descriptions-item label="主体类型">
              企业
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions class="toTop" title="主体信息" bordered>
            <a-descriptions-item label="营业执照" v-if="false">
              <a-avatar
                v-if="business_license_info.license_copy"
                :src="business_license_info.license_copy"
                @click="handlePreview(business_license_info.license_copy)"
                alt="营业执照">
              </a-avatar>
            </a-descriptions-item>
            <a-descriptions-item label="统一社会信用代码">
              {{ business_license_info.license_number }}
            </a-descriptions-item>
            <a-descriptions-item label="商户名称">
              {{ business_license_info.merchant_name }}
            </a-descriptions-item>
            <a-descriptions-item label="法定代表人姓名">
              {{ business_license_info.legal_person }}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions class="toTop" title="法定代表人证件" bordered>
            <a-descriptions-item label="证件类型">
              中国大陆居民-身份证
            </a-descriptions-item>
            <a-descriptions-item label="身份证人像面照片" v-if="false">
              <a-avatar
                v-if="identity_info.id_card_info.id_card_copy"
                :src="identity_info.id_card_info.id_card_copy"
                @click="handlePreview(identity_info.id_card_info.id_card_copy)"
                alt="身份证人像面照片" />
            </a-descriptions-item>
            <a-descriptions-item label="身份证国徽面照片" v-if="false">
              <a-avatar
                v-if="identity_info.id_card_info.id_card_national"
                :src="identity_info.id_card_info.id_card_national"
                @click="handlePreview(identity_info.id_card_info.id_card_national)"
                alt="身份证国徽面照片">
              </a-avatar></a-descriptions-item>
            <a-descriptions-item label="身份证姓名">
              {{ id_card_info.id_card_name }}
            </a-descriptions-item>
            <a-descriptions-item label="身份证号码">
              {{ id_card_info.id_card_number }}
            </a-descriptions-item>
            <a-descriptions-item label="证件有效期类型">
              定期/长期
            </a-descriptions-item>
            <a-descriptions-item label="身份证有效期开始时间">
              {{ id_card_info.card_period_begin }}
            </a-descriptions-item>
            <a-descriptions-item label="身份证有效期结束时间">
              {{ id_card_info.card_period_end }}
            </a-descriptions-item>
            <a-descriptions-item label="经营者/法人是否为受益人">
              {{ owner?'是':'否' }}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions class="toTop" title="经营信息" bordered>
            <a-descriptions-item label="商户简称">
              {{ business_info.merchant_shortname }}
            </a-descriptions-item>
            <a-descriptions-item label="客服电话" :span="2">
              {{ business_info.service_phone }}
            </a-descriptions-item>
            <a-descriptions-item label="经营场景">
              小程序
            </a-descriptions-item>
            <a-descriptions-item label="商家小程序AppID">
              {{ mini_program_info.mini_program_sub_appid }}
            </a-descriptions-item>
            <a-descriptions-item label="小程序截图">
              <a-avatar :src="item" v-for="(item,index) in mini_program_info.mini_program_pics" :key="index" @click="handlePreview(item)"/>
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions class="toTop" title="结算规则" bordered>
            <a-descriptions-item label="结算规则信息">
              <p>餐饮、零售批发、网上综合商城、交通出行、生活娱乐服务、培训教育机构、民营医疗机构、缴费等</p>
              费率0.6%，入账周期T+1
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions class="toTop" title="结算银行账户" bordered >
            <a-descriptions-item label="账户类型">
              对公银行账户
            </a-descriptions-item>
            <a-descriptions-item label="开户名称" :span="2">
              {{ bank_account_info.account_name }}
            </a-descriptions-item>
            <a-descriptions-item label="开户银行">
              {{ bank_account_info.account_bank }}
            </a-descriptions-item>
            <a-descriptions-item label="基本开户支行">
              {{ bank_account_info.bank_name }}
            </a-descriptions-item>
            <a-descriptions-item label="银行账号">
              {{ bank_account_info.account_number }}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions class="toTop" title="超级管理员信息" bordered>
            <a-descriptions-item label="超级管理员信息">
              法定代表人/经营者
            </a-descriptions-item>
            <a-descriptions-item label="超管姓名" :span="2">
              {{ contact_info.contact_name }}
            </a-descriptions-item>
            <a-descriptions-item label="超级管理员身份证件号码">
              {{ contact_info.contact_id_number }}
            </a-descriptions-item>
            <a-descriptions-item label="联系手机">
              {{ contact_info.mobile_phone }}
            </a-descriptions-item>
            <a-descriptions-item label="联系邮箱">
              {{ contact_info.contact_email }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </a-card>
    </a-spin>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

import { addMerchants, merchants_get_sign_url, merchants_status } from '@/api/wx_pay_applyment'

let wxPayApplymentBody = {}
let wxDataBody = { data: {} }
export default {
  name: 'StepForm',
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5
  },
  data () {
    return {
      currentTab: 0,
      // form
      form: null,
      previewVisible: false,
      previewImage: '',
      appletLoading: false,
      apply_success: -1,
      apply_title: '',
      qrData: '',
      applyData: undefined,
      owner: false,
      identity_info: {},
      id_card_info: {},
      business_license_info: {},
      business_info: {},
      mini_program_info: {},
      bank_account_info: {},
      contact_info: {},
      apply_subtitle: '',
      merchantsStatus: this.$Dictionaries.merchants_status,
      stepTitles: [
        { title: '主体信息', description: '请填写商家的营业执照/登记证书、经营者/法人的证件等信息' },
        { title: '经营信息', description: '请填写商家的经营业务信息、售卖商品/提供服务场景信息' },
        { title: '结算规则', description: '请填写商家的结算费率规则、特殊资质等信息' },
        { title: '结算账户', description: '请填写商家提现收款的银行账户信息' },
        { title: '超级管理员', description: '请填写商家的超级管理员信息， 超级管理员需在开户后进行签约，并接收日常重要管理信息和进行资金操作，请确定其为商户法定代表人或负责人' }
      ]
    }
  },
  methods: {
    // 进口图片
    handlePreview (url) {
      this.previewImage = url
      this.previewVisible = true
    },
    handleCancel (file) {
      this.previewVisible = false
    },
    // handler
    nextStep (obj, index) {
      console.log(obj, index)
      if (index === 1) {
        wxPayApplymentBody.subject_info = obj
      } else if (index === 2) {
        wxPayApplymentBody.business_info = obj
      } else if (index === 3) {
        wxPayApplymentBody.settlement_info = obj
      } else if (index === 4) {
        wxPayApplymentBody.bank_account_info = obj
      }
      console.log(wxPayApplymentBody)
      if (this.currentTab < this.stepTitles.length - 1) {
        this.currentTab += 1
      }
    },
    prevStep () {
      console.log(this.currentTab)
      if (this.currentTab > 0) {
        this.currentTab -= 1
      }
    },
    finish (obj, index) {
    if (index === 5) {
      wxPayApplymentBody.contact_info = obj
      console.log(wxPayApplymentBody)
      wxDataBody.data = wxPayApplymentBody
      addMerchants(wxDataBody).then(res => {
        console.log(res)
      })
    }
    },
    wechatPay () {
      this.getMerchantsStatus()
    },
    getMerchantsStatus () {
      this.appletLoading = true
      merchants_status().then(res => {
        this.appletLoading = false
        if (res.status === this.merchantsStatus.REJECT) {
          this.apply_success = 0
          this.$message.error('提交审核不通过，请重新申请')
        } if (res.status === this.merchantsStatus.WAITING) {
          this.apply_success = 0
          this.$message.error('未申请')
        } else if (res.status === this.merchantsStatus.TOBESIGNED) {
          this.apply_title = '审核中'
          this.apply_subtitle = '法人微信扫描二维码'
          this.apply_success = 1
        } else if (res.status === this.merchantsStatus.EXAMINING) {
          this.apply_title = '审核中'
          this.apply_subtitle = '请耐心等待'
          this.apply_success = 1
        } else if (res.status === this.merchantsStatus.DONE) {
          this.apply_title = '申请成功'
          this.apply_subtitle = '恭喜您，微信支付申请成功'
          this.apply_success = 4
          this.merchantsGetSignUrl()
        } else {
          this.apply_title = '微信支付资料提交成功'
          this.apply_subtitle = '本次支付申请微信审核通过后，更新状态'
        }
      })
    },
    merchantsGetSignUrl () {
      this.appletLoading = true
      merchants_get_sign_url().then(res => {
        this.appletLoading = false
        this.qrData = res.sign_url
        this.applyData = res.data
        this.business_license_info = this.applyData.subject_info.business_license_info
        this.identity_info = this.applyData.subject_info.identity_info
        this.id_card_info = this.identity_info.id_card_info
        this.owner = this.applyData.subject_info.owner
        this.business_info = this.applyData.business_info
        this.mini_program_info = this.business_info.sales_info.mini_program_info
        this.bank_account_info = this.applyData.bank_account_info
        this.contact_info = this.applyData.contact_info
      })
    }
  },
  mounted () {
    this.getMerchantsStatus()
  }
}
</script>

<style lang="less" scoped>
  .steps {
    max-width: 1250px;
    margin: 16px auto;
  }
  .toTop {
   margin-top: 30px
  }
</style>
