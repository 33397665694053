<template>
  <div>
    <a-form :form="form">
      <a-form-item
        label="商户简称"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-input
          style="width: 60%;margin-right: 10px"
          v-decorator="[
            'merchant_shortname',
            { rules: [ {required: true, message: '请输入商户简称' }]}]"
          placeholder="商户简称"/>
        <a-tooltip placement="right" :style="{ marginLeft: `0px`, whiteSpace: 'nowrap' }">
          <template slot="title">
            <div>
              <p>1、在支付完成页向买家展示，需与微信经营类目相关。</p>
              <p>2、简称要求：</p>
              <p>① 不支持单纯以人名来命名，若为个体户经营，可用“个体户+经营者名称”或“经营者名称+业务”命名，如“个体户张三”或“张三餐饮店”；</p>
              <p>② 不支持无实际意义的文案，如“XX特约商户”、“800”、“XX客服电话XXX”；</p>
            </div>
          </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </a-form-item>
      <a-form-item
        label="客服电话"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-input
          style="width: 60%;margin-right: 10px"
          v-decorator="[
            'service_phone',
            { rules: [{required: true, message: '请输入客服电话'}] }]"
          placeholder="客服电话"/>
        <a-tooltip placement="right" :style="{ marginLeft: `0px`, whiteSpace: 'nowrap' }">
          <template slot="title">
            <div>
              <p>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</p>
              <p>2、请确保电话畅通，以便入驻后平台回拨确认。</p>
            </div>
          </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </a-form-item>
      <a-form-item
        label="经营场景"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol">
        <a-checkbox-group
          :options="plainOptions"
          :default-value="['小程序']"
          @change="onChange"
          v-decorator="[
            'sales_scenes_type',
            { rules: [{required: true, message: '请选择经营场景'}] }]"
          disabled/>
        <br/>
        <span>请勾选实际售卖商品/提供服务场景（至少一项），以便为你开通需要的支付权限<a target="_blank" href="https://kf.qq.com/faq/170807nY7Jbi170807Yf6jIB.html">填写经营场景指引</a></span>
        <br/>
        <span>建议只勾选目前必须的场景，以便尽快通过入驻审核，其他支付权限你可在入驻后再根据实际需要发起申请</span>
      </a-form-item>
      <a-form-item
        label="小程序APPID"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-input
          style="width: 60%;margin-right: 10px"
          v-decorator="[
            'mini_program_sub_appid',
            { rules: [{required: true, message: '请输入小程序APPID'}] }]"
          placeholder="小程序APPID"/>
        <br/>
        <span>
          <span>1、请填写已认证的小程序APPID。<a href="https://kf.qq.com/faq/181105JJNbmm181105eUZfee.html" target="_blank">AppID查看指引</a></span>
          <br />
          <span>2、完成进件后，系统发起特约商户号与该AppID的绑定（即配置为sub_appid，可在发起支付时传入）。</span>
          <br />
          <span>（1）若APPID主体与商家主体/服务商主体一致，则直接完成绑定。</span>
          <br />
          <span>（2）若APPID主体与商家主体/服务商主体不一致，则商户签约时显示《联合营运承诺函》，并且AppID的管理员需登录公众平台确认绑定意愿。</span>
        </span>
      </a-form-item>
      <a-form-item label="小程序截图" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <div class="clearfix">
          <a-upload
            v-decorator="[
              'mini_program_pics',
              { rules: [{required: true, message: '请上传小程序截图'}]
              },]"
            name="file"
            accept="image/png, image/jpeg, image/jpg"
            list-type="picture-card"
            :action="actionUrl"
            :data="new_multipart_params"
            :file-list="fileList"
            :before-upload="beforeUpload"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="fileList.length < 5">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
          <span>请提供展示商品/服务的页面截图/设计稿（最多5张），若小程序未建设完善或未上线请务必提供。</span>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-form-item>
      <a-form-item :wrapperCol="{span: 19, offset: 5}">
        <a-button style="margin-right: 8px" @click="prevStep">上一步</a-button>
        <a-button :loading="loading" type="primary" @click="nextStep">下一步</a-button>
      </a-form-item>
    </a-form>
    <a-divider />
    <div class="step-form-style-desc">
      <h3>说明</h3>
      <p>请填写商家的经营业务信息、售卖商品/提供服务场景信息</p>
    </div>
  </div>
</template>

<script>
import { Dictionaries } from '@/common/AllConstant'

const plainOptions = ['线下门店', '公众号', '小程序', 'App', '互联网网站', '企业微信']
let business_info = {
  merchant_shortname: '',
  service_phone: '',
  sales_info: {
    sales_scenes_type: ['SALES_SCENES_MINI_PROGRAM'],
    mini_program_info: {
      mini_program_sub_appid: '',
      mini_program_pics: []
    }
  }
}
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'Step2',
  data () {
    return {
      plainOptions,
      business_info,
      previewImage: false,
      fileList: [],
      mediaIds: [],
      new_multipart_params: {},
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this),
      loading: false,
      timer: 0,
      actionUrl: 'http://192.168.2.17:8000/api/v1/wechat/add_merchants_image_upload/',
      previewVisible: false
    }
  },
  created () {
    this.actionUrl = process.env.VUE_APP_API_BASE_URL + 'wechat/add_merchants_image_upload/'
  },
  methods: {
    nextStep () {
      const that = this
      const { form: { validateFields } } = this
      that.loading = true
      validateFields((err, values) => {
        if (!err) {
          console.log('表单 values', values)
          this.business_info.merchant_shortname = values.merchant_shortname
          this.business_info.service_phone = values.service_phone
          this.business_info.sales_info.mini_program_info.mini_program_sub_appid = values.mini_program_sub_appid
          that.timer = setTimeout(function () {
            that.loading = false
            that.$emit('nextStep', that.business_info, 2)
          }, 500)
        } else {
          that.loading = false
        }
      })
    },
    prevStep () {
      this.$emit('prevStep')
    },
    onChange () {
      console.log('1111111111')
    },
    handleCancel () {
      this.previewVisible = false
    },
    beforeUpload (file) {
      this.new_multipart_params.key = file.uid + '_' + file.name
      this.new_multipart_params.corporation_id = Dictionaries.corporation_id
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      return isJpgOrPng && isLt5M
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      console.log('我要预览', file.preview)
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'done') {
        this.mediaIds.push(file.response.data)
        this.form.setFieldsValue({ mini_program_pics: this.mediaIds })
        this.business_info.sales_info.mini_program_info.mini_program_pics = this.mediaIds
      } else if (file.status === 'removed') {
        this.mediaIds.splice(file.response.data, 1)
        this.form.setFieldsValue({ id_card_copy: this.mediaIds })
        this.business_info.sales_info.mini_program_info.mini_program_pics = this.mediaIds
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="less" scoped>
  .stepFormText {
    margin-bottom: 24px;

    .ant-form-item-label,
    .ant-form-item-control {
      line-height: 22px;
    }
  }

  .step-form-style-desc {
    padding: 0 56px;
    color: rgba(0,0,0,.45);

    h3 {
      margin: 0 0 12px;
      color: rgba(0,0,0,.45);
      font-size: 16px;
      line-height: 32px;
    }

    h4 {
      margin: 0 0 4px;
      color: rgba(0,0,0,.45);
      font-size: 14px;
      line-height: 22px;
    }

    p {
      margin-top: 0;
      margin-bottom: 12px;
      line-height: 22px;
    }
  }

</style>
