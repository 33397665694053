import request from '@/utils/request'
const appletApi = {
  image_upload: '/wechat/add_merchants_image_upload/',
  add_merchants: '/wechat/add_merchants/',
  merchants_status: '/wechat/merchants_status/',
  merchants_get_sign_url: '/wechat/merchants_get_sign_url/'
}
export function imageUpload (parameter) {
  return request({
    url: appletApi.image_upload,
    method: 'post',
    timeout: 60000, // 请求超时时间
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: parameter
  })
}
export function addMerchants (parameter) {
  return request({
    url: appletApi.add_merchants,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function merchants_status () {
  return request({
    url: appletApi.merchants_status,
    method: 'get'
  })
}

export function merchants_get_sign_url () {
  return request({
    url: appletApi.merchants_get_sign_url,
    method: 'get'
  })
}

let requestBody = {
  contact_info: {},
  subject_info: {},
  business_info: {},
  settlement_info: {},
  bank_account_info: {},
  addition_info: {}
}
export default requestBody
