<template>
  <div>
    <a-form :form="form">
      <a-form-item
        label="超管身份"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-radio-group
          v-model="admin_role"
          @change="onAdminRoleChange">
          <a-radio :value="1">法定代表人/经营者
            <a-tooltip :style="{ marginLeft: `0px`, whiteSpace: 'nowrap' }" placement="right">
              <template slot="title">
                <div>
                  <p>若超管为法定代表人/经营者，则该商户入驻流程为：提交申请 - 平台审核 - 超管签约 - 完成入驻</p>
                </div>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </a-radio>
          <a-radio :value="2" disabled>负责人
            <a-tooltip :style="{ marginLeft: `0px`, whiteSpace: 'nowrap' }" placement="right">
              <template slot="title">
                <div>
                  <p>若超管为负责人，则该商户入驻流程为：提交申请 - 平台审核 -账户验证- 超管签约 - 完成入驻</p>
                </div>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </a-radio>
        </a-radio-group>
        <br/>
        <span v-if="false">若营业执照注册号为18位统一社会信用代码，请选择“已三证合一”，否则请选择“未三证合一”</span>
      </a-form-item>
      <a-form-item
        label="超管姓名"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol">
        <a-input v-decorator="['contact_name', { initialValue: '', rules: [{required: true, message: '请输入超管姓名'}]}]" placeholder="请输入超管姓名" />
      </a-form-item>
      <a-form-item
        label="超管资料类型"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol">
        <a-radio-group
          v-model="admin_file_type"
          @change="onAdminFileTypeChange">
          <a-radio :value="1">证件号码</a-radio>
          <a-radio :value="2" disabled>微信openId</a-radio>
        </a-radio-group>
        <br/>
        <span v-if="false">若营业执照注册号为18位统一社会信用代码，请选择“已三证合一”，否则请选择“未三证合一”</span>
      </a-form-item>
      <a-form-item
        label="证件号码"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol">
        <a-input v-decorator="['contact_id_number', { initialValue: '', rules: [{required: true, message: '请输入证件号码'}]}]" placeholder="请填写超级管理员的证件号码，可传身份证、来往内地通行证、来往大陆通行证、护照等证件号码" />
      </a-form-item>
      <a-form-item
        label="手机号码"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol">
        <a-input addon-before="+86" v-decorator="['mobile_phone', { rules: [{required: true, message: '请输入手机号码'}]}]" placeholder="请填写手机号" />
        <span>用于接收微信支付的重要管理信息及日常操作验证码，超管签约时可自助修改手机号，并进行短信验证</span>
      </a-form-item>
      <a-form-item
        label="邮箱"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol">
        <a-input v-decorator="['contact_email', { initialValue: '', rules: [{required: true, message: '请输入邮箱'}]}]" placeholder="请填写邮箱" />
        <span>用于接收微信支付的开户邮件及日常业务通知</span>
      </a-form-item>
      <a-form-item :wrapperCol="{span: 19, offset: 5}">
        <a-button style="margin-right: 8px" @click="prevStep">上一步</a-button>
        <a-button :loading="loading" type="primary" @click="finish">提交</a-button>
      </a-form-item>
    </a-form>
    <a-divider />
    <div class="step-form-style-desc">
      <h3>说明</h3>
      <p>请填写商家的超级管理员信息， 超级管理员需在开户后进行签约，并接收日常重要管理信息和进行资金操作，请确定其为商户法定代表人或负责人</p>
    </div>
  </div>
</template>

<script>
let contact_info = { contact_name: '', contact_id_number: '', mobile_phone: '', contact_email: '' }
export default {
  name: 'Step5',
  data () {
    return {
      admin_role: 1,
      admin_file_type: 1,
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      loading: false,
      contact_info,
      form: this.$form.createForm(this)
    }
  },
  methods: {
    onAdminRoleChange ({ target }) {
      this.admin_role = target.value
    },
    onAdminFileTypeChange ({ target }) {
      this.admin_file_type = target.value
    },
    toOrderList () {
      this.$router.push('/list/table-list')
    },
    finish () {
      const that = this
      const { form: { validateFields } } = this
      that.loading = true
      validateFields((err, values) => {
        if (!err) {
          that.contact_info.contact_name = values.contact_name
          that.contact_info.contact_id_number = values.contact_id_number
          that.contact_info.mobile_phone = values.mobile_phone
          that.contact_info.contact_email = values.contact_email
          that.timer = setTimeout(function () {
            that.loading = false
            console.log('--------------------', that.contact_info)
            that.$emit('finish', that.contact_info, 5)
          }, 500)
        } else {
          that.loading = false
          console.log(err)
        }
      })
    },
    prevStep () {
      this.$emit('prevStep')
    }
  }
}
</script>
<style lang="less" scoped>
.step-form-style-desc {
  padding: 0 56px;
  color: rgba(0,0,0,.45);

  h3 {
    margin: 0 0 12px;
    color: rgba(0,0,0,.45);
    font-size: 16px;
    line-height: 32px;
  }

  h4 {
    margin: 0 0 4px;
    color: rgba(0,0,0,.45);
    font-size: 14px;
    line-height: 22px;
  }

  p {
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 22px;
  }
}
</style>
