<template>
  <div>
    <div>
      <a-form :form="form">
        <a-divider orientation="left">
          营业执照
        </a-divider>
        <a-form-item label="营业执照照片" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div class="clearfix">
            <a-upload
              v-decorator="['license_copy',{ rules: [{required: true, message: '必传项，请上传'}]
              },]"
              name="file"
              accept="image/png, image/jpeg, image/jpg"
              :action="actionUrl"
              :data="new_multipart_params"
              list-type="picture-card"
              :file-list="licenseCopyFileList"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="handleLicenseCopyChange"
            >
              <div v-if="licenseCopyFileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-form-item>
        <a-form-item
          label="统一社会信用代码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input v-decorator="['license_number', { initialValue: '', rules: [{required: true, message: '必填项，请填写信息'}]}]" />
        </a-form-item>
        <a-form-item
          label="商户名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input v-decorator="['merchant_name', { initialValue: '', rules: [{required: true, message: '必填项，请填写信息'}]}]" />

        </a-form-item>
        <a-form-item
          label="法定代表人姓名"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input v-decorator="['legal_person', { initialValue: '', rules: [{required: true, message: '必填项，请填写信息'}]}]" />

        </a-form-item>
        <a-form-item
          label="类型"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-radio-group
            v-model="typeValue"
            @change="onTypeChange">
            <a-radio :value="1">已三证合一</a-radio>
            <a-radio :value="2" disabled>未三证合一</a-radio>
          </a-radio-group>
          <br/>
          <span>若营业执照注册号为18位统一社会信用代码，请选择“已三证合一”，否则请选择“未三证合一”</span>
        </a-form-item>
        <a-divider orientation="left">
          法定代表人证件
        </a-divider>
        <a-form-item
          label="证件类型"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input v-decorator="['id_doc_type', { initialValue: '中国大陆居民-身份证', rules: [{required: true, message: '证件类型'}] }]" disabled/>
        </a-form-item>
        <a-form-item label="身份证人像面照片" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div class="clearfix">
            <a-upload
              v-decorator="['id_card_copy',{ rules: [{required: true, message: '必传项，请上传'}]
              },]"
              name="file"
              accept="image/png, image/jpeg, image/jpg"
              :action="actionUrl"
              :data="new_multipart_params"
              list-type="picture-card"
              :file-list="idCardCopyFileList"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="handleIdCardCopyChange"
            >
              <div v-if="idCardCopyFileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-form-item>
        <a-form-item label="身份证国徽面照片" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div class="clearfix">
            <a-upload
              v-decorator="['id_card_national',{ rules: [{required: true, message: '必传项，请上传'}]
              },]"
              name="file"
              accept="image/png, image/jpeg, image/jpg"
              :action="actionUrl"
              :data="new_multipart_params"
              list-type="picture-card"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="handle_id_card_national_Change"
            >
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-form-item>
        <a-form-item
          label="身份证号码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input v-decorator="['id_card_number', { rules: [{required: true, message: '必填项，请填写信息'}] }]" placeholder="身份证号码" />
        </a-form-item>
        <a-form-item
          label="身份证姓名"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input v-decorator="['id_card_name', { rules: [{required: true, message: '必填项，请填写信息'}] }]" placeholder="身份证姓名" />
        </a-form-item>
        <a-form-item
          label="证件有效期类型"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-radio-group
            v-model="certificate_validity"
            @change="onCertificateValidityChange">
            <a-radio :value="1">定期</a-radio>
            <a-radio :value="2">长期</a-radio>
          </a-radio-group>
          <br/>
          <span v-if="false">若营业执照注册号为18位统一社会信用代码，请选择“已三证合一”，否则请选择“未三证合一”</span>
        </a-form-item>
        <a-form-item
          label="证件生效日期"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol">
          <a-date-picker
            v-decorator="['card_period_begin', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            show-time
            :disabled-date="disabledStartDate"
            format="YYYY-MM-DD"
            placeholder="证件生效日期"
            @openChange="handleStartOpenChange"
            @change="handleCardPeriodChange"
          />
        </a-form-item>
        <a-form-item
          v-if="certificate_validity===1"
          label="证件失效日期"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol">
          <a-date-picker
            v-decorator="['card_period_end', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            show-time
            :open="endOpen"
            :disabled-date="disabledEndDate"
            format="YYYY-MM-DD"
            placeholder="证件结束日期"
            @openChange="handleEndOpenChange"
            @change="handleCardPeriodEndChange"
          />
        </a-form-item>
        <a-form-item
          label="是否为受益所有人"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-radio-group
            v-model="is_beneficiary"
            @change="onTypeChange">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="2" disabled>否，非经营者</a-radio>
          </a-radio-group>
          <a-tooltip :style="{ marginLeft: `0px`, whiteSpace: 'nowrap' }" placement="right">
            <template slot="title">
              <div>
                <p>根据国家相关法律法规，您需要提供公司受益所有人信息。受益所有人需符合至少以下条件之一：</p>
                <p>1. 直接或者间接拥有超过25%公司股权或者表决权的自然人；</p>
                <p>2. 通过人事、财务等其他方式对公司进行控制的自然人；</p>
                <p>3. 公司的高级管理人员，包括公司的经理、副经理、财务负责人，上市公司董事会秘书和公司章程规定的其他人员</p>
              </div>
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </a-form-item>
        <a-form-item :wrapperCol="{span: 19, offset: 5}">
          <a-button type="primary" @click="nextStep">下一步</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-divider />
    <div class="step-form-style-desc">
      <h3>说明</h3>
      <p>请填写商家的营业执照/登记证书、经营者/法人的证件等信息</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { Dictionaries } from '@/common/AllConstant'
/*
import { imageUpload } from '@/api/wx_pay_applyment'
*/
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
let subject_info = { subject_type: 'SUBJECT_TYPE_ENTERPRISE',
  business_license_info: { license_copy: '', license_number: '', merchant_name: '', legal_person: '' },
  identity_info: { id_doc_type: 'IDENTIFICATION_TYPE_IDCARD',
    id_card_info: {
      id_card_name: '',
      id_card_number: '',
      card_period_begin: '',
      card_period_end: ''
    } },
  owner: true
}
export default {
  name: 'Step1',
  data () {
    return {
      time (dates, dateStrings) {
        console.log(dateStrings, '时间')
        this.queryParam.start_time = dateStrings[0]
        this.queryParam.end_time = dateStrings[1]
      },
      moment: moment,
      subject_info,
      new_multipart_params: {},
      actionUrl: 'http://192.168.2.17:8000/api/v1/wechat/add_merchants_image_upload/',
      typeValue: 1,
      certificate_validity: 1,
      is_beneficiary: 1,
      licenseCopyFileList: [],
      idCardCopyFileList: [],
      fileList: [],
      previewVisible: false,
      startValue: null,
      endValue: null,
      endOpen: false,
      card_period_begin: null,
      card_period_end: null,
      previewImage: '',
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this)
    }
  },
  created () {
    this.actionUrl = process.env.VUE_APP_API_BASE_URL + 'wechat/add_merchants_image_upload/'
  },
  methods: {
    nextStep () {
      // 先校验，通过表单校验后，才进入下一步
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values, 133)
          // this.subject_info.business_license_info.license_copy = values.license_copy
          this.subject_info.business_license_info.license_number = values.license_number
          this.subject_info.business_license_info.merchant_name = values.merchant_name
          this.subject_info.business_license_info.legal_person = values.legal_person
          // this.subject_info.identity_info.id_card_info.id_card_copy = values.id_card_copy
          // this.subject_info.identity_info.id_card_info.id_card_national = values.id_card_national
          this.subject_info.identity_info.id_card_info.id_card_name = values.id_card_name
          this.subject_info.identity_info.id_card_info.id_card_number = values.id_card_number
          this.subject_info.identity_info.id_card_info.card_period_begin = this.card_period_begin
          this.subject_info.identity_info.id_card_info.card_period_end = this.card_period_end
          this.subject_info.owner = values.owner
          this.$emit('nextStep', this.subject_info, 1)
          console.log(this.subject_info)
        }
      })
    },
    beforeUpload (file) {
      this.new_multipart_params.key = file.uid + '_' + file.name
      this.new_multipart_params.corporation_id = Dictionaries.corporation_id
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      return isJpgOrPng && isLt5M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      console.log('我要预览', file.preview)
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleLicenseCopyChange ({ fileList, file }) {
      console.log(file, 898989)
      this.licenseCopyFileList = fileList
      if (file.status === 'done') {
        console.log(file.response.data)
        this.form.setFieldsValue({ license_copy: file.response.data })
        this.subject_info.business_license_info.license_copy = file.response.data
      } else if (file.status === 'removed') {
        this.form.setFieldsValue({ license_copy: null })
        this.subject_info.business_license_info.license_copy = null
      }
    },
    handleIdCardCopyChange ({ fileList, file, event }) {
      this.idCardCopyFileList = fileList
      console.log(event)
      if (file.status === 'done') {
        this.form.setFieldsValue({ id_card_copy: file.response.data })
        this.subject_info.identity_info.id_card_info.id_card_copy = file.response.data
      } else if (file.status === 'removed') {
        this.subject_info.identity_info.id_card_info.id_card_copy = undefined
        this.form.setFieldsValue({ id_card_copy: null })
      }
    },
    handle_id_card_national_Change ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'done') {
        console.log(file.response.data)
        this.form.setFieldsValue({ id_card_national: file.response.data })
        console.log(this.form.getFieldValue('id_card_national'))
        this.subject_info.identity_info.id_card_info.id_card_national = file.response.data
      } else if (file.status === 'removed') {
        this.form.setFieldsValue({ id_card_national: null })
        this.subject_info.identity_info.id_card_info.id_card_national = undefined
      }
    },
    onTypeChange ({ target }) {
      console.log(target.value, this.is_beneficiary)
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleCancel (file) {
      this.previewVisible = false
      console.log(file, '我是删除')
    },
    onCertificateValidityChange ({ target }) {
      console.log(target.value, this.certificate_validity)
    },
    disabledStartDate (startValue) {
      this.endValue = this.form.getFieldValue('card_period_end')
      const endValue = this.endValue
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate (endValue) {
      this.startValue = this.form.getFieldValue('card_period_begin')
      const startValue = this.startValue
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleCardPeriodChange (date, dateString) {
      this.card_period_begin = dateString
    },
    handleCardPeriodEndChange (date, dateString) {
      this.card_period_end = dateString
    },
    handleStartOpenChange (open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    }
  }
}
</script>

<style lang="less" scoped>
.step-form-style-desc {
  padding: 0 56px;
  color: rgba(0,0,0,.45);

  h3 {
    margin: 0 0 12px;
    color: rgba(0,0,0,.45);
    font-size: 16px;
    line-height: 32px;
  }

  h4 {
    margin: 0 0 4px;
    color: rgba(0,0,0,.45);
    font-size: 14px;
    line-height: 22px;
  }

  p {
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 22px;
  }
}
</style>
