<template>
  <div>
    <a-form :form="form">
      <a-form-item
        label="账户类型"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        class="stepFormText"
      >
        对公银行账户
        <br />
        你为经营者对公银行账户，请务必填写开户名为的银行账号
      </a-form-item>
      <a-form-item
        label="开户名称"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        class="stepFormText"
      >
        <a-input
          v-decorator="['account_name', { initialValue: '', rules: [{required: true, message: '请输入开户名称'}] }]"
          placeholder="对公银行账户”时，开户名称必须与营业执照/登记证书的“商户名称”一致" />
      </a-form-item>
      <a-form-item
        label="开户行"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        class="stepFormText"
      >
        <a-select
          @change="handleChange"
          v-decorator="['account_bank',{rules: [ { required: true, message: '请选择开户行' }, ], }, ]"
          placeholder="请选择银行">
          <a-select-option :value="item.name" v-for="(item,index) in banks" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <br />
        <span>城市商业银行、农村商业银行、信用合作联社及其他银行,请选择“其他银行”</span>
      </a-form-item>
      <a-form-item
        label="其他银行"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        v-if="false"
        class="stepFormText">
        <a-input v-decorator="['other_bank_name', { initialValue: '', rules: [{required: true, message: '请输入银行名称'}] }]" placeholder="请输入银行" />
      </a-form-item>
      <a-form-item
        label="省市"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        class="stepFormText"
      >
        <a-select
          allowClear
          style="width: 200px"
          v-decorator="['province', { rules: [{required: true, message: '请选择省'}] }]"
          placeholder="请选择省"
          @change="handleProvinceChange">
          <a-select-option :value="province.id" v-for="(province, index) in provinces" :key="index">
            {{ province.province }}
          </a-select-option>
        </a-select>
        <a-select
          allowClear
          style="width: 200px; margin-left: 20px"
          v-decorator="['city', { rules: [{required: true, message: '请选择市'}] }]"
          placeholder="请选择市"
          @change="handleChange">
          <a-select-option :value="city.id" v-for="(city, index) in cities" :key="index">
            {{ city.city }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="开户支行"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        class="stepFormText">
        <a-input v-decorator="['bank_name', { initialValue: '', rules: [{required: true, message: '请输入银行名称'}] }]" placeholder="请输入开户银行全称（含支行）" />
      </a-form-item>
      <a-form-item
        label="银行账号"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        class="stepFormText">
        <a-input v-decorator="['account_number', { initialValue: '', rules: [{required: true, message: '请输入银行账号'}] }]" placeholder="请输入银行账号" />
      </a-form-item>
      <a-form-item :wrapperCol="{span: 19, offset: 5}">
        <a-button style="margin-right: 8px" @click="prevStep">上一步</a-button>
        <a-button :loading="loading" type="primary" @click="nextStep">下一步</a-button>
      </a-form-item>
    </a-form>
    <a-divider />
    <div class="step-form-style-desc">
      <h3>说明</h3>
      <p>请填写商家提现收款的银行账户信息</p>
    </div>
  </div>
</template>

<script>
import { provinceCity } from '@/api/wx_province_city'

const banks = [
  { name: '中国工商银行', id: 1 },
  { name: '中国农业银行', id: 2 },
  { name: '中国银行', id: 3 },
  { name: '中国建设银行', id: 4 },
  { name: '交通银行', id: 5 },
  { name: '中信银行', id: 1 },
  { name: '中国光大银行', id: 1 },
  { name: '华夏银行', id: 1 },
  { name: '中国民生银行', id: 1 },
  { name: '广发银行', id: 1 },
  { name: '平安银行', id: 1 },
  { name: '招商银行', id: 1 },
  { name: '兴业银行', id: 1 },
  { name: '上海浦东发展银行', id: 1 },
  { name: '上海银行', id: 1 },
  { name: '中国邮政储蓄银行', id: 1 },
  { name: '汇丰银行（中国）', id: 1 },
  { name: '北京银行', id: 1 },
  { name: '宁波银行', id: 1 },
  { name: '其他银行', id: 1 }
]
let bank_account_info = {
  bank_account_type: 'BANK_ACCOUNT_TYPE_CORPORATE',
  account_name: '',
  account_bank: '',
  bank_address_code: '',
  bank_name: '',
  account_number: ''
}
export default {
  name: 'Step4',
  data () {
    return {
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this),
      loading: false,
      timer: 0,
      banks,
      provinces: [],
      cities: [],
      bank_account_info
    }
  },
  methods: {
    nextStep () {
      const that = this
      const { form: { validateFields } } = this
      that.loading = true
      validateFields((err, values) => {
        if (!err) {
          values.bank_account_type = 'BANK_ACCOUNT_TYPE_CORPORATE'
          this.bank_account_info.account_name = values.account_name
          this.bank_account_info.account_bank = values.account_bank
          this.bank_account_info.bank_address_code = values.city
          this.bank_account_info.bank_name = values.bank_name
          this.bank_account_info.account_number = values.account_number
          console.log('表单 values', values)
          console.log('表单 bank_account_info', this.bank_account_info)
          that.timer = setTimeout(function () {
            that.loading = false
            that.$emit('nextStep', that.bank_account_info, 4)
          }, 500)
        } else {
          that.loading = false
        }
      })
    },
    prevStep () {
      this.$emit('prevStep')
    },
    handleChange (e) {
      console.log('click left button', e)
      this.bank_account_info.account_bank = e
    },
    handleProvinceChange (province) {
      this.cities = undefined
      this.form.setFieldsValue({
        'city': undefined
      })
      this.cityData(province)
    },
    onChange () {
      console.log('1111111111')
    },
    handleMenuClick (e) {
      console.log('click', e)
    },
    provinceData () {
      provinceCity().then(res => {
        this.provinces = res.data
      })
    },
    cityData (province) {
      const data = { 'province_id': province }
      provinceCity(data).then(res => {
        this.cities = res.data
      })
    }
  },
  mounted () {
    this.provinceData()
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="less" scoped>
.stepFormText {
  margin-bottom: 24px;

  .ant-form-item-label,
  .ant-form-item-control {
    line-height: 22px;
  }
}

.change_style {
  text-align: center
}

.change_style:hover {
  background: transparent;
  border: solid 2px rgba(0, 0, 218, 0.6);
  text-align: center
}

.step-form-style-desc {
  padding: 0 56px;
  color: rgba(0, 0, 0, .45);

  h3 {
    margin: 0 0 12px;
    color: rgba(0, 0, 0, .45);
    font-size: 16px;
    line-height: 32px;
  }

  h4 {
    margin: 0 0 4px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px;
  }

  p {
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 22px;
  }
}

</style>
